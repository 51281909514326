<template>   
    <div class="flex justify-content-center mt-3">
        <SelectButton v-model="showReadNotifications" :options="options" optionLabel="name" optionValue="value" aria-labelledby="basic" />
    </div>
    <DisplayNotifications 
    :isReadMode="showReadNotifications"
    :data="notificationData"
    @notificationHandeled="handleNotificationHandeled"
    />
    
</template>


<script>
import DisplayNotifications from './DisplayNotifications.vue'

export default {
    components: {
        DisplayNotifications,
    },

    data() {
        return {
            showReadNotifications: false,
            
            readNotifications: null,
            unreadNotifications: null,
            options: [{name: "Ungelesene Benachrichtigungen", value: false}, {name: "Gelesene Benachrichtigungen", value: true}]
        }
    },

    computed: {
        notificationData(){
            console.log("computed property is called")
            if (this.showReadNotifications){
                return this.readNotifications
            } else {
                return this.unreadNotifications
            }
        }
    },

    methods: {
        updateState() {
            this.readNotifications = this.$store.getters.getReadNotifications
            this.unreadNotifications = this.$store.getters.getUnreadNotifications
        },

        handleNotificationHandeled() {
            console.log("handleNotificationHandeled -> updating state")
            this.updateState()
        }
    },

    created() {
        this.updateState()
    }
}
</script>