<template>
    <div class="block">
        <p class="p-0 mb-1 text-600 font-semibold">{{absenceTypeDescription}} <span>{{ absenceAction }}</span></p>
        <h5 class="p-0 m-0">{{data.meta.firstName}} {{data.meta.lastName}}</h5>
        <p class="p-0 m-0">Von: <span class="line-through">{{data.meta.original.start_date}}</span> {{data.meta.new.start_date}}</p>
        <p class="p-0 m-0">Bis: <span class="line-through">{{data.meta.original.end_date}}</span> {{data.meta.new.end_date}}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        absenceTypeDescription(){
            if (this.data.type == "vacation") {
                return "Urlaubsantrag"
            } else if (this.data.type == "sickness") {
                return "Krankheitsantrag"
            } else {
                return "Undefiniert"
            }
        },
        absenceAction(){
            if (this.data.action == "delete") {
                return "(Löschung)"
            } else if (this.data.action == "update") {
                return "(Änderung)"
            } else {
                return ""
            }
        }
    },

    mounted(){
    }
}
</script>
