import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import store from './store/store';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('./components/UserLogin.vue'),
        alias: '/'
    },
    
    {
        path: '/app',
        name: 'app',
        component: App,
        children: [
            {
                path: '/changepassword',
                name: 'changepassword',
                component: () => import('./components/PasswordChanger.vue'),
                alias: '/'
            },
            {
                path: '/employeeprofile',
                name: 'employeeprofile',
                component: () => import('./components/DisplayEmployeeProfile.vue'),
                alias: '/'
            },
            {
                path: '/landingpage',
                name: 'landingpage',
                component: () => import('./components/LandingPages/LandingPage.vue')
            },
            {
                path: '/employeetable',
                name: 'employeetable',
                component: () => import('./components/EmployeeTable/EmployeeTable.vue')
            },
            {
                path: '/additionalfieldsadder',
                name: 'additionalfieldsadder',
                component: () => import('./components/AdditionalFieldsAdder.vue'),
                children: [
                    {
                        path: '/additionalfieldsadder/qualificationsadder',
                        component: () => import('./components/additionalFields/QualificationAdder.vue'),
                        alias: '/additionalfieldsadder'
                    },
                    {
                        path: '/additionalfieldsadder/teamsadder',
                        component: () => import('./components/additionalFields/TeamAdder.vue')
                    },
                    {
                        path: '/additionalfieldsadder/functionsadder',
                        component: () => import('./components/additionalFields/TeamFunctionAdder.vue')
                    },
                    {
                        path: '/additionalfieldsadder/departmentadder',
                        component: () => import('./components/additionalFields/DepartmentAdder.vue')
                    },
                    {
                        path: '/additionalfieldsadder/locationadder',
                        component: () => import('./components/additionalFields/LocationAdder.vue')
                    },
                ],
            },
            {
                path: '/workplaceadder',
                name: 'workplaceadder',
                component: () => import('./components/WorkplaceAdder.vue')
            },
            {
                path: '/workplacegroupadder',
                name: 'workplacegroupadder',
                component: () => import('./components/WorkplaceGroupAdder.vue')
            },

            {
                path: '/shiftrhythmadder',
                name: 'shiftrhythmadder',
                component: () => import('./components/ShiftrhythmAdder.vue')
            },
            {
                path: '/availabilityadder',
                name: 'availabilityadder',
                component: () => import('./components/AvailabilityAdder.vue')
            },
            {
                path: '/notimplemntedyet',
                name: 'notimplemntedyet',
                component: () => import('./components/NotImplementedYet.vue')
            },
            {
                path: '/demandplanner',
                name: 'demandplanner',
                // component: () => import('./components/DemandPlannerCustom.vue')
                component: () => import('./components/GanttChartAdder.vue')
            },
            {
                path: '/holidayplanner',
                name: 'holidayplanner',
                // component: () => import('./components/DemandPlannerCustom.vue')
                component: () => import('./components/HolidayPlanning/HolidayPlanner.vue')
            },
            {
                path: '/employeetimetracking',
                name: 'employeetimetracking',
                component: () => import('./components/TimeTracking/EmployeeTimeTracker.vue')
            },
        ]
    },
];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
});

  router.beforeEach((to, from, next) => {
    console.log(`Navigating to ${to.name}...`);

    // Exclude the login route from the check.
    if (to.name !== 'login') {
      // If the user is not authenticated, redirect to the login page.
      if (!store.getters.getIsAuthenticated) {
        console.log('User is not authenticated. Redirecting to login page...');
        next({ name: 'login' });
      } else {
        console.log('User is authenticated. Proceeding to next route...');
        next(); // The user is authenticated so they can proceed to the next route.
      }
    } else {
      console.log('This is the login route. Proceeding...');
      next(); // This is the login route, so just proceed.
    }
  })


export default router;
