<template>
    <div class="block">
        <p v-if="this.data.action === 'create'" class="p-0 mb-1 text-600 font-semibold">Die Beantragung ihrer Stempelzeit wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <p v-else-if="this.data.action === 'update'" class="p-0 mb-1 text-600 font-semibold">Die Änderung ihrer Stempelzeit wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <p v-else-if="this.data.action === 'delete'" class="p-0 mb-1 text-600 font-semibold">Die Löschung ihrer Stempelzeit wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <h5 class="p-0 m-0">{{data.meta.firstName}} {{data.meta.lastName}}</h5>
        <p class="p-0 m-0">Aktion: {{timeTrackingAction}}</p>
        <p class="p-0 m-0">Zeitpunkt: {{data.meta.timestamp}}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
 
        absenceDecision(){
            if (this.data.status === "accepted") {
                return "angenommen."
            } else if (this.data.status === "declined") {
                return "abgelehnt."
            } else if (this.data.status === "abandoned") {
                return "fallen gelassen."
            } else {
                return "Undefiniert"
            }
        },

        decisionClass() {
            if (this.data.status === "accepted") {
                return 'bg-green-100 p-1 border-round';
            } else if (this.data.status === "declined") {
                return 'bg-red-100 p-1 border-round';
            } else if (this.data.status === "abandoned") {
                return 'bg-yellow-100 p-1 border-round';
            }
            console.log("returning nothing")
            return ''; // Default, no background
        },

        timeTrackingAction(){
            if (this.data.meta.action == "check-in") {
                return "Einstempeln"
            } else if (this.data.action == "check-out") {
                return "Ausstempeln"
            } else {
                return ""
            }
        }
    },



    mounted(){
        console.log("UpdateTimeTrackingDetails")
        console.log(this.data)
    }
}
</script>