<template>
    <UpdateAbsenceDetails
    v-if="data.type === 'vacation' || data.type === 'sickness'"
    :data="data"
    />

    <UpdateTimeTrackingDetails
    v-else-if="(data.type === 'time_tracking')"
    :data="data"
    />

    <div v-else>
        <h6>
            Not implemented notification type
        </h6>
    </div>
</template>

<script>
import UpdateAbsenceDetails from './UpdateAbsenceDetails.vue'
import UpdateTimeTrackingDetails from './UpdateTimeTrackingDetails.vue'
export default {
    components: {
        UpdateAbsenceDetails,
        UpdateTimeTrackingDetails
    },

    data() {
        return {

        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    mounted(){
        console.log("Update")
        console.log(this.data)
    }
}
</script>