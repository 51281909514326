<template>
    <div class="block">
        <p class="p-0 mb-1 text-600 font-semibold">{{ absenceAction }}</p>
        <h5 class="p-0 m-0">{{data.meta.firstName}} {{data.meta.lastName}}</h5>
        <p class="p-0 m-0">Aktion: {{timeTrackingAction}}</p>
        <p class="p-0 m-0">Zeitpunkt: {{data.meta.timestamp}}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        timeTrackingAction(){
            if (this.data.meta.action == "check-in") {
                return "Einstempeln"
            } else if (this.data.meta.action == "check-out") {
                return "Ausstempeln"
            } else {
                return ""
            }
        },

        absenceAction(){
            if (this.data.action == "create") {
                return "Antrag Eintrag Zeiterfassung"
            } else if (this.data.action == "delete") {
                return "Antrag Löschung Eintrag Zeiterfassung"
            } else {
                return ""
            }
        }
    },

    mounted(){
    }
}
</script>
