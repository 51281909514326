<template>
    <RequestAbsenceDetails
    v-if="(data.type === 'vacation' || data.type === 'sickness') && (data.action === 'create' || data.action === 'delete')"
    :data="data"
    />

    <RequestAbsenceUpdateDetails
    v-else-if="(data.type === 'vacation' || data.type === 'sickness') && data.action === 'update'"
    :data="data"
    />

    <RequestTimeTrackingDetails
    v-else-if="(data.type === 'time_tracking') && (data.action === 'create' || data.action === 'delete')"
    :data="data"
    />

    <RequestTimeTrackingUpdateDetails
    v-else-if="(data.type === 'time_tracking') && data.action === 'update'"
    :data="data"
    />

    <div v-else>
        <h6>
            Not implemented notification type
        </h6>
    </div>
</template>

<script>
import RequestAbsenceDetails from './RequestAbsenceDetails.vue'
import RequestAbsenceUpdateDetails from './RequestAbsenceUpdateDetails.vue'
import RequestTimeTrackingDetails from './RequestTimeTrackingDetails.vue'
import RequestTimeTrackingUpdateDetails from './RequestTimeTrackingUpdateDetails.vue'

export default {
    components: {
        RequestAbsenceDetails,
        RequestAbsenceUpdateDetails,
        RequestTimeTrackingDetails,
        RequestTimeTrackingUpdateDetails
    },

    data() {
        return {

        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    mounted(){
        console.log("RequestDetails")
    }
}
</script>