import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import ApiClient from '../js_utilities/apiClient';
import {parseISO} from 'date-fns'

const apiClient = new ApiClient(process.env.VUE_APP_BASE_URL);

// TODO: find a way to use something like caseclasses to better define what a certain 
// elemt in the store represents
const workplacesModule = {
  state: () => ({ 
    absenceTypes: [
        {
            label: "Urlaub", value: "vacation"
        },
        {
            label: "Krankheit", value: "sickness"
        }
    ],
    absenceDurationOptions: [
        {
            label: "Ganzer Tag", value: "full", durationNum: 1.0              
        },
        {
            label: "Vormittag", value: "morning", durationNum: 0.5
        },
        {
            label: "Nachmittag", value: "afternoon", durationNum: 0.5
        }
    ],
    roleOptions: [{name: "base"}, {name: "admin"}],
    sexOptions: [{name: "m"}, {name: "w"}, {name: "d"}],
    employeeDateFields: ["dateOfBirth", "hireDate", "endOfContract", "shiftRhythmStartDate"],
    jwtToken: null,
    jwtRefreshToken: null,
    loggedInUser: null,
    userRole: null,
    teams: [],
    teamFunctions: [],
    departments: [],
    locations: [],
    workplaces: [],
    workplaceGroups: [],
    qualifications: [],
    employees: [],
    shiftRhythms: [],
    availabilities: [],
    demandPlans: {},
    demandPlansMetaData: [],
    readNotifications: [],
    unreadNotifications: []
  }),

    
  mutations: {
    logoutUser(state) {
      state.jwtToken=null,
      state.jwtRefreshToken=null,
      state.loggedInUser=null,
      state.userRole=null,
      state.teams=[],
      state.teamFunctions=[],
      state.departments=[],
      state.locations=[],
      state.workplaces=[],
      state.workplaceGroups=[],
      state.qualifications=[],
      state.employees=[],
      state.shiftRhythms=[],
      state.Availabilities=[],
      state.demandPlans={},
      state.demandPlansMetaData=[],
      state.readNotifications=[],
      state.unreadNotifications=[]
    },
    initialiseNotifications(state, payload){
      state.readNotifications = payload.read
      state.unreadNotifications = payload.unread
    },
    verifyUser(state, payload){
      state.jwtToken = payload.tokens.jwt_token
      state.jwtRefreshToken = payload.tokens.jwt_refresh_token

      state.loggedInUser = payload.user
      state.userRole = payload.user.role
    },
    // ------------ Availbility Types ---------------
    initialiseAvailabilities(state, payload){
      state.Availabilities = payload
    },

    deleteAvailability(state, id){
      state.Availabilities = state.Availabilities.filter(obj => obj.id != id )
    },

    // ------------ Shiftrhytms Types ---------------
    initialiseShiftRhythms(state, payload){
      state.shiftRhythms = payload
    },

    deleteShiftRhythm(state, id){
      state.shiftRhythms = state.shiftRhythms.filter(obj => obj.id != id )
    },

    // ------------ WorkplaceGroups Types ---------------
    initialiseWorkplaceGroups(state, payload){
      state.workplaceGroups = payload
    },

    deleteWorkplaceGroup(state, id){
      state.workplaceGroups = state.workplaceGroups.filter(obj => obj.id != id )
    },

    // ------------ Workplace Types ---------------
    initialiseWorkplaceTypes(state, payload){
      state.workplaces = payload
    },

    deleteWorkplaceType(state, id){
      state.workplaces = state.workplaces.filter(obj => obj.id != id )
    },

    // ------------ Employees ---------------
    initialiseEmployees(state, payload){
      var filteredEmployees = payload.data
      
      filteredEmployees = filteredEmployees.map((employee) => {
				state.employeeDateFields.forEach((field) => {
					if (typeof employee[field] === 'string' || employee[field] instanceof String) {
						employee[field] = parseISO(employee[field], new Date())
					}
				})
				employee["department"] = state.departments.find(x => x.name === employee["department"])
				employee["location"] = state.locations.find(x => x.name === employee["location"])
				employee["team"] = state.teams.find(x => x.name === employee["team"])
				employee["teamFunction"] = state.teamFunctions.find(x => x.name === employee["teamFunction"])
				employee["shiftRhythm"] = state.shiftRhythms.find(x => x.name === employee["shiftRhythm"])
				employee["availability"] = state.Availabilities.find(x => x.name === employee["availability"])
				employee["sex"] = state.sexOptions.find(x => x.name === employee["sex"])
        employee["role"] = state.roleOptions.find(x => x.name === employee["role"])
				// employee["qualifications"] = this.sexOptions.filter(x => employee["qualifications"].includes(x.name))
				return employee
			})

      state.employees = filteredEmployees
      console.log("initialiseEmployees")
      console.log(filteredEmployees)


    },

    deleteEmployee(state, id){
      state.employees = state.employees.filter(obj => obj.uuid != id )
    },

    // ------------ Qualifications ---------------
    initialiseQualifications(state, payload) {
      state.qualifications = payload.data
    },

    addQualification(state, payload){
      state.qualifications.push(payload)
    },

    deleteQualification(state, id){
      state.qualifications = state.qualifications.filter(obj => obj.id != id )
    },

    // ------------ Teams ---------------
    initialiseTeams(state, payload) {
      state.teams = payload.data
    },

    addTeam(state, payload){
      state.teams.push(payload)
    },

    deleteTeam(state, id){
      state.teams = state.teams.filter(obj => obj.id != id )
    },


    // ------------ Team Functions ---------------
    initialiseTeamFunctions(state, payload) {
      state.teamFunctions = payload.data
    },

    addTeamFunction(state, payload){
      state.teamFunctions.push(payload)
    },

    deleteTeamFunction(state, id){
      state.teamFunctions = state.teamFunctions.filter(obj => obj.id != id )
    },

    // ------------ Department Functions ---------------
    initialiseDepartments(state, payload) {
      state.departments = payload.data
    },

    addDepartment(state, payload){
      state.departments.push(payload)
    },

    deleteDepartment(state, id){
      state.departments = state.departments.filter(obj => obj.id != id )
    },


    // ------------ Location Functions ---------------
    initialiseLocations(state, payload) {
      state.locations = payload.data
    },

    addLocation(state, payload){
      state.locations.push(payload)
    },

    deleteLocation(state, id){
      state.locations = state.locations.filter(obj => obj.id != id )
    },

    // ------------ Demand Plan Meta Data Functions ---------------

    initialisePlanMetaData(state, payload) {
      let payloadParsed = payload.map((planMeta) =>{
        // planMeta["startDate"] =  parseISO(planMeta["startDate"], new Date())
        // planMeta["endDate"] =  parseISO(planMeta["endDate"], new Date())
        planMeta["startDate"] =  new Date(planMeta["startDate"])
        planMeta["endDate"] =  new Date(planMeta["endDate"])
        return planMeta
      })
      state.demandPlansMetaData = payloadParsed
    },

    deleteDemandPlan(state, id) {
      state.demandPlansMetaData = state.demandPlansMetaData.filter(obj => obj.id != id )
    },

    deleteSolution(state, id) {
      state.demandPlansMetaData = state.demandPlansMetaData.map(obj => ({
        ...obj,
        solutions: obj.solutions.filter(solution => solution.id !== id)
      }));
    }
  },


  getters: {
    getReadNotifications(state){
      return state.readNotifications
    },
    getUnreadNotifications(state){
      return state.unreadNotifications
    },

    getAbsenceTypes(state){
      return state.absenceTypes
    },

    getAbsenceDurationOptions(state){
      return state.absenceDurationOptions
    },

    getRoles(state) {
      return state.roleOptions
    },

    getTeams(state) {
      return state.teams;
    },
    getTeamFunctions(state) {
      return state.teamFunctions;
    }, 
    getDepartments(state) {
      return state.departments;
    }, 
    getLocations(state) {
      return state.locations;
    }, 


    getWorkplaces (state) {
      return state.workplaces
    },
    getWorkplaceGroups(state) {
      return state.workplaceGroups
    },
    getEmployees(state) {
      return state.employees
    },

    getEmployeeById: (state) => (id) => {
      return state.employees.find((obj) => obj.uuid === id)
    },

    getGanttChart(state) {
      return state.ganttChartRows
    },
    getGanttChartRowNames(state) {
      return Object.keys(state.ganttChartRows)
    },
    getSkills(state) {
      return state.qualifications;
    },
    getQualifications(state) {
      return state.qualifications;
    },
    getShiftRhythmNames(state){
      return Object.keys(state.shiftRhythms)
    },
    getAvailabilities(state){
      return state.Availabilities
    },
    getShiftRhythms(state){
      return state.shiftRhythms
    },
    getDemandPlansMetaData(state){
      return state.demandPlansMetaData
    },
    getDemandPlans(state){
      return state.demandPlans
    },

    getDemandPlanById: (state) => (id) => {
      return state.demandPlans[id]
    },
    getLoggedInUser(state){
      return state.loggedInUser
    },
    getJwtToken(state){
      return state.jwtToken
    },
    getJwtRefreshToken(state){
      return state.jwtRefreshToken
    },
    getIsAuthenticated(state){
      if (state.jwtToken !== null) {
        return true
      } else {
        return false
      }
      
    }
  },
  actions: {
    logoutUser({ commit }) {
      // Perform any additional logout logic here, such as clearing local storage or making API requests.

      // Call the logout mutation to reset the store state.
      commit('logoutUser');
      return "success"
    },

    async verifyUser({commit}, payload){
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
      };
  
      let url = process.env.VUE_APP_BASE_URL + "auth/login_user"
      console.log("url login user")
      console.log(url)
      console.log("process.env.VUE_APP_BASE_URL")
        console.log(process.env.VUE_APP_BASE_URL)
      const response = await fetch(url, requestOptions)

      if (response.ok) {
        let data = await response.json()
        apiClient.setAuthToken(data.tokens.jwt_token)
        commit('verifyUser', data)
        
        console.log("apiClient.authToken")
        console.log(apiClient.headers)
        

        return "success" 
      } else {
        // If status code is 401, throw a specific error message
        if(response.status === 401){
            throw new Error('Username or password is incorrect')
        } else {
            // For other errors, throw a generic error message
            throw new Error('Login failed')
        }
      }
    },

    // <------------------ Qualification API calls -------------------->
    async updateQualificationsFromDb({ commit }) {
      const data = await apiClient.getRequest("qualifications/")
      commit('initialiseQualifications', data)
      return "success"
    },

    async addQualification(_, payload) {
      await apiClient.postRequest("qualifications/", payload)
      return "success"  
    },

    async deleteQualification({commit}, id) {
      let endpoint = "qualifications/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteQualification', id)
      return "success"  

    },

    // <------------------ TEAM API calls -------------------->
    async updateTeamsFromDb({ commit }) {
      const data = await apiClient.getRequest("teams/")
      commit('initialiseTeams', data)
      return "success"
   
      // commit a mutation to set the data in the store
    
    },

    async addTeam(_, payload) {
      await apiClient.postRequest("teams/", payload)
      return "success"  
    },

    async deleteTeam({commit}, id) {
      let endpoint = "teams/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteTeam', id)
      return "success"  

    },

    // <------------------ TEAMFUNCTIONS API calls -------------------->
    async updateTeamFunctionsFromDb({ commit }) {
      const data = await apiClient.getRequest("team_functions/")
      commit('initialiseTeamFunctions', data)
      return "success"

      // commit a mutation to set the data in the store
    
    },

    async addTeamFunction(_, payload) {
      await apiClient.postRequest("team_functions/", payload)
      return "success"  
    },

    async deleteTeamFunction({commit}, id) {
      let endpoint = "team_functions/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteTeamFunction', id)
      return "success" 

    },

    // <------------------ DEPARTMENTS API calls -------------------->
    async updateDepartmentsFromDb({ commit }) {
      const data = await apiClient.getRequest("departments/")
      commit('initialiseDepartments', data)
      return "success"  
      // commit a mutation to set the data in the store
    },

    async addDepartment(_, payload) {
      await apiClient.postRequest("departments/", payload)
      return "success"  

    },

    async deleteDepartment({commit}, id) {
      let endpoint = "departments/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteDepartment', id)
      return "success" 
    },

    // <------------------ LOCATIONS API calls --------------------> 
    async updateLocationsFromDb({ commit }) {
      const data = await apiClient.getRequest("locations/")
      commit('initialiseLocations', data)
      return "success"
    },

    async addLocation(_, payload) {      
      await apiClient.postRequest("locations/", payload)
      return "success"  
    },

    async deleteLocation({commit}, id) {
      let endpoint = "locations/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteLocation', id)
      return "success" 

    },

    // <------------------ Employee API calls -------------------->
 
    async updateEmployeesFromDb({ commit }) {
      const data = await apiClient.getRequest("employees/")
      console.log("updateEmployeesFromDb")
      console.log(data)
      commit('initialiseEmployees', data)
      return "success"

      // commit a mutation to set the data in the store
    
    },

    async updateEmployee(_, employee) {
      let endpoint = "employees/update/" + employee.uuid
      await apiClient.putRequest(endpoint, employee)
      return "success" 

    },

    async addEmployee(_, employee) { 
      console.log("Employee in addEmployeeAction")
      console.log(employee)
      await apiClient.postRequest("employees/", employee)
      return "success" 

    },

    async deleteEmployee({commit}, id) {
      let endpoint = "employees/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteEmployee', id)
      return "success" 
    },

    // <------------------ Workplace Types API calls -------------------->
    async updateWorkplaceTypesFromDb({ commit }) {
      const data = await apiClient.getRequest("workplace_types/")
      commit('initialiseWorkplaceTypes', data)
      return "success"
    },

    async addWorkplaceType(_, workplaceType) {
      await apiClient.postRequest("workplace_types/", workplaceType)
      return "success" 
    },

    async updateWorkplaceType(_, payload) {
      let endpoint = "workplace_types/update/" + payload["id"]
      await apiClient.putRequest(endpoint, payload["data"])
      return "success" 

    },

    async deleteWorkplaceType({commit}, id) {
      let endpoint = "workplace_types/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteWorkplaceType', id)
      return "success" 
    },

    // <------------------ WorkplaceGroup API calls -------------------->
    async updateWorkplaceGroupsFromDb({commit}) {
      const data = await apiClient.getRequest("workplace_groups/")
      commit('initialiseWorkplaceGroups', data)
      return "success"

    },

    async addWorkplaceGroup(_, workplaceGroup) {
      await apiClient.postRequest("workplace_groups/", workplaceGroup)
      return "success" 
    },

    async deleteWorkplaceGroup({commit}, id) {
      let endpoint = "workplace_groups/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteWorkplaceGroup', id)
      return "success" 

    },

    async updateWorkplaceGroup(_, payload) {
      let endpoint = "workplace_groups/update/" + payload["id"]
      await apiClient.putRequest(endpoint, payload["data"])
      return "success" 
    },

    // <------------------ SHIFTRHYTHM API calls -------------------->
    async addShiftRhythm(_, shiftRhythm) {
      await apiClient.postRequest("shift_rhythms/", shiftRhythm)
      return "success" 
    },


    async updateShiftRhythmsFromDb({commit}) {
      const data = await apiClient.getRequest("shift_rhythms/")
      commit('initialiseShiftRhythms', data)
      return "success"
    },

    async deleteShiftRhythm({commit}, id) {
      let endpoint = "shift_rhythms/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteShiftRhythm', id)
      return "success" 
    },

    async updateShiftRhythm(_, payload){
      let endpoint = "shift_rhythms/update/" + payload["id"]
      await apiClient.putRequest(endpoint, payload)
      return "success" 
    },

    // <------------------ AVAILABILITY API calls -------------------->
    async addAvailability(_, availability) {
      await apiClient.postRequest("availabilities/", availability)
      return "success"
    },

    async updateAvailabilitiesFromDb({commit}) {
      const data = await apiClient.getRequest("availabilities/")
      commit('initialiseAvailabilities', data)
      return "success"
    },

    async deleteAvailability({commit}, id) {
      let endpoint = "availabilities/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteAvailability', id)
      return "success" 
    },

    async updateAvailability(_, payload){
      let endpoint = "availabilities/update/" + payload["id"]
      await apiClient.putRequest(endpoint, payload)
      return "success" 
    },

    // <------------------ DEMANDPLAN API calls -------------------->
    async updateDemandPlanMetaDataFromDb({commit}) {
      const data = await apiClient.getRequest("demand_plans/meta_data_solutions/")
      commit('initialisePlanMetaData', data)
      return "success"
    },

    async addDemandPlan(_, payload) {
        await apiClient.postRequest("demand_plans/", payload)
        return "success"
    },

    async updateDemanPlan(_, payload) {
      let endpoint = "demand_plans/update/" + payload["id"]
      await apiClient.putRequest(endpoint, payload)
      return "success" 
    },

    async deleteDemandPlan({commit}, id) {
      let endpoint = "demand_plans/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteDemandPlan', id)
      return "success" 
    },

    async deleteSolution({commit}, id) {
      let endpoint = "opta/solutions/" + id
      await apiClient.deleteRequest(endpoint)
      commit('deleteSolution', id)
      return "success" 
    },

    async updateNotificationsFromDb({commit}, id) {
      let endpoint = "notifications/get_notifications_employee_id/" + id
      const data = await apiClient.getRequest(endpoint)
      commit('initialiseNotifications', data)
      return "success"
    },

  }
}



const store = createStore({
  modules: {
    workplaces: workplacesModule,
  },
  plugins: [createPersistedState()]
})

export default store;