

<template>
    
        <button class="p-link layout-menu-button layout-topbar-button" @click="toggle" aria-controls="overlay_menu" aria-haspopup="true">
			<i class="pi pi-cog"></i>
		</button>
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
        <Toast />
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    label: 'Optionen',
                    items: [
                        {
                            label: 'Profil anzeigen',
                            icon: 'pi pi-user',
                            command: () => {
                                this.$router.push({ name: 'employeeprofile' });
                            }
                        },
                        {
                            label: 'Passwort ändern',
                            icon: 'pi pi-lock',
                            command: () => {
                                this.$router.push({ name: 'changepassword' });
                            }
                        },
                        {
                            label: 'Logout',
                            icon: 'pi pi-sign-out',
                            command: () => {
                                // solve this
                                this.$router.push({ name: 'login' }).then(() => {
                                    this.$store.dispatch('logoutUser')
                                })
                            }
                        }
                    ]
                },
            ]
        };
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        save() {
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Data Saved', life: 3000 });
        }
    },
};
</script>