<template>
    <div class="block">
        <p v-if="this.data.action === 'create'" class="p-0 mb-1 text-600 font-semibold">Ihr {{absenceTypeDescription}} wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <p v-else-if="this.data.action === 'update'" class="p-0 mb-1 text-600 font-semibold">Die Änderung Ihres {{absenceTypeDescription}}s wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <p v-else-if="this.data.action === 'delete'" class="p-0 mb-1 text-600 font-semibold">Die Löschung Ihres {{absenceTypeDescription}}s wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <h5 class="p-0 m-0">{{data.meta.firstName}} {{data.meta.lastName}}</h5>
        <p class="p-0 m-0">Von: {{data.meta.start_date}}</p>
        <p class="p-0 m-0">Bis: {{data.meta.end_date}}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        absenceTypeDescription(){
            if (this.data.type === "vacation") {
                return "Urlaubsantrag"
            } else if (this.data.type === "sickness") {
                return "Krankheitsantrag"
            } else {
                return "Undefiniert"
            }
        },

        absenceDecision(){
            if (this.data.status === "accepted") {
                return "angenommen."
            } else if (this.data.status === "declined") {
                return "abgelehnt."
            } else if (this.data.status === "abandoned") {
                return "fallen gelassen."
            } else {
                return "Undefiniert"
            }
        },

        decisionClass() {
            if (this.data.status === "accepted") {
                return 'bg-green-100 p-1 border-round';
            } else if (this.data.status === "declined") {
                return 'bg-red-100 p-1 border-round';
            } else if (this.data.status === "abandoned") {
                return 'bg-yellow-100 p-1 border-round';
            }
            console.log("returning nothing")
            return ''; // Default, no background
        }
    },

    mounted(){
        console.log("UpdateAbsenceDetails")
        console.log(this.data)
    }
}
</script>