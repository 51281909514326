<template>
    <div class="block">
        <p class="p-0 mb-1 text-600 font-semibold">Antrag Änderung Eintrag Zeiterfassung</p>
        <h5 class="p-0 m-0">{{data.meta.firstName}} {{data.meta.lastName}}</h5>
        <p class="p-0 m-0">Aktion: <span class="line-through">{{timeTrackingActionOld}}</span> {{timeTrackingActionNew}}</p>
        <p class="p-0 m-0">Zeitpunkt: <span class="line-through">{{data.meta.original.timestamp}}</span> {{data.meta.new.timestamp}}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        timeTrackingActionOld(){
            if (this.data.meta.original.action == "check-in") {
                return "Einstempeln"
            } else if (this.data.meta.original.action == "check-out") {
                return "Ausstempeln"
            } else {
                return ""
            }
        },

        timeTrackingActionNew(){
            if (this.data.meta.new.action == "check-in") {
                return "Einstempeln"
            } else if (this.data.meta.new.action == "check-out") {
                return "Ausstempeln"
            } else {
                return ""
            }
        }
    },

    mounted(){
    }
}
</script>
